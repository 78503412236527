@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Manrope', sans-serif;
}

body {
  background: #090909;
}

@screen ms {
  html {
    font-size: 20px;
  }
}

@screen mm {
  html {
    font-size: 20px;
  }
}

@screen ml {
  html {
    font-size: 20px;
  }
}

@screen t {
  html {
    font-size: 20px;
  }
}

@screen dxs {
  html {
    font-size: 16px; 
  }
}

@screen ds {
  html {
    font-size: 20px;
  }
}

@screen dm {
  html {
    font-size: 20px;
  }
}

@screen dl {
  html {
    font-size: 20px;
  }
}

@screen dxl {
  html {
    font-size: 20px;
  }
}

@font-face {
  font-family: 'PressStart2P';
  src: url('./resources/fonts/Press_Start_2P/PressStart2P-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CodersCrux';
  src: url('./resources/fonts/Coders_Crux/coders_crux.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  .contour-shadow {
    box-shadow: .1rem .1rem #CDCCC5;
  }

  .outline-title {
     text-shadow:
         1px 1px 0 #F4F3EB,
       -1px -1px 0 #F4F3EB,
        1px -1px 0 #F4F3EB,
        -1px 1px 0 #F4F3EB,
         1px 1px 0 #F4F3EB;
  }

  .tab-shadow {
    box-shadow: .1rem .1rem 0 rgba(0, 0, 0, 0.25)
  }

  .no-scrollbar {
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .markdown p {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .markdown li {
    
  }

  .markdown ul li {
    position: relative;
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin: 1.1rem 0;
    padding-left: 1.5rem;
  }

  .markdown ul li::before {
    content: '';
    position: absolute;
    left:0;
    top: 0.6rem;
    background: #F4F3EB;
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 0.3rem;
  }

  .markdown img {
    margin: 1rem 0 1.5rem;
  }

  .markdown iframe.video, .markdown video {
    width: 100%;
    height: 28rem;
  }

  .dashboard-card-gradient-1 {
    background: repeating-linear-gradient(
    45deg,
    rgba(244, 243, 235, 0.1),
    rgba(244, 243, 235, 0.1) 10px,
    transparent 10px,
    transparent 20px
    );
  }

  .home-page-greenGradient {
    background-image: linear-gradient(45deg, #34deb3 25%, #35ebbc 25%, #35ebbc 50%, #34deb3 50%, #34deb3 75%, #35ebbc 75%, #35ebbc 100%);
    background-size: 56.57px 56.57px;
  }

  .home-page-upcomingGradient {
    background-image: linear-gradient(45deg, #a6a6a6 2.17%, #f4f3eb 2.17%, #f4f3eb 50%, #a6a6a6 50%, #a6a6a6 52.17%, #f4f3eb 52.17%, #f4f3eb 100%);
    background-size: 32.53px 32.53px;
  }

  .home-page-noDataGradient {
    background-image: linear-gradient(45deg, #0e0e0e 25%, #090909 25%, #090909 50%, #0e0e0e 50%, #0e0e0e 75%, #090909 75%, #090909 100%);
    background-size: 56.57px 56.57px;
  }

  table {
    border-spacing: 0;
  }

  table th {
    font-weight: 500;
  }

  .vesting-unlock-shadow {
    box-shadow: 0px 0px 12px rgba(255, 133, 251, 0.6);
  }
}

.react-tooltip-custom {
  color: #7F7E7A !important;
  font-size: 0.7rem !important;
  line-height: 140%;
  background: #F4F3EB !important;
  box-shadow: 0rem 0.1rem 0.6rem rgba(0, 0, 0, 0.1);
  opacity: 1 !important; 
  text-transform: initial !important;
  padding: 0.4rem 0.6rem !important; 
  z-index: 1000 !important;
  max-width: 13rem !important;
  border-radius: 0 !important;
  border: 0.1rem solid #9A9A9A;
}
.react-tooltip-custom.place-top::after {
  border-top-color: #F4F3EB !important;
  border-top-style: #F4F3EB !important;
  border-top-width: 0.3rem !important;
  bottom: -0.29rem !important;
}
.react-tooltip-custom.place-bottom::after {
  border-bottom-color: #F4F3EB !important;
  border-bottom-style: #F4F3EB !important;
  border-bottom-width: 0.3rem !important;
  bottom: -0.29rem !important;
}
